import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
var EmailValidationContent = (function (_super) {
    __extends(EmailValidationContent, _super);
    function EmailValidationContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], EmailValidationContent.prototype, "loadingDots", void 0);
    __decorate([
        Prop()
    ], EmailValidationContent.prototype, "icon", void 0);
    __decorate([
        Prop()
    ], EmailValidationContent.prototype, "message", void 0);
    EmailValidationContent = __decorate([
        Component({
            components: {
                BeatLoader: BeatLoader
            }
        })
    ], EmailValidationContent);
    return EmailValidationContent;
}(Vue));
export default EmailValidationContent;
