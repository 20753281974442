var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verify" }, [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "card-content card-alignment" },
        [
          _c(
            "transition-group",
            { staticClass: "transition", attrs: { name: "slide-bottom" } },
            _vm._l(_vm.messages, function(msg) {
              return _c("email-validation-content", {
                key: msg.icon,
                attrs: {
                  loadingDots: _vm.loadingDots,
                  message: msg.message,
                  icon: msg.message
                }
              })
            }),
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/bim-meet-icon.png") }
        }),
        _c("span", { staticClass: "has-text-info has-text-weight-medium" }, [
          _vm._v("BIM")
        ]),
        _c("span", { staticClass: "has-text-primary has-text-weight-bold" }, [
          _vm._v("Meet")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }