import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import EmailValidationContent from '@/components/auth/EmailValidationContent.vue';
var VerifyEmail = (function (_super) {
    __extends(VerifyEmail, _super);
    function VerifyEmail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mode = null;
        _this.actionCode = null;
        _this.lang = null;
        _this.continueUrl = null;
        _this.loadingDots = true;
        _this.messages = [
            {
                icon: 'dots',
                message: 'Verifying your email'
            }
        ];
        return _this;
    }
    VerifyEmail.prototype.beforeMount = function () {
        this.actionCode = this.$route.query.oobCode;
        this.lang = this.$route.query.lang;
        this.continueUrl = this.$route.query.continueUrl;
        this.mode = this.$route.query.mode;
        if (!this.mode)
            this.$router.go(-1);
    };
    VerifyEmail.prototype.mounted = function () {
        var _this = this;
        this.userWait()
            .then(function () {
            return _this.handleUserManagementAction();
        })
            .then(function () {
            return _this.userWait();
        })
            .then(function () {
            _this.$router.push('/user-profile');
        })
            .catch(function () { });
    };
    VerifyEmail.prototype.userWait = function (seconds) {
        if (seconds === void 0) {
            seconds = 1000;
        }
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, seconds);
        });
    };
    VerifyEmail.prototype.handleUserManagementAction = function () {
        switch (this.mode) {
            case 'resetPassword':
                return this.handleResetPassword(this.actionCode, this.continueUrl, this.lang);
            case 'recoverEmail':
                return Promise.all([
                    this.handleRecoverEmail(this.actionCode, this.lang),
                    this.userWait()
                ]).then(function () {
                    return;
                });
            case 'verifyEmail':
                return this.handleVerifyEmail(this.actionCode, this.continueUrl, this.lang);
            default:
        }
    };
    VerifyEmail.prototype.handleRecoverEmail = function (actionCode, lang) {
        var _this = this;
        var restoredEmail = null;
        return this.$auth
            .checkActionCode(actionCode)
            .then(function (info) {
            restoredEmail = info['data']['email'];
            return _this.$auth.applyActionCode(actionCode);
        })
            .then(function () {
            _this.$auth
                .sendPasswordResetEmail(restoredEmail)
                .then(function () {
            })
                .catch(function (error) {
                console.error(error.message);
            });
        })
            .catch(function (error) {
            console.error(error.message);
        });
    };
    VerifyEmail.prototype.handleVerifyEmail = function (actionCode, continueUrl, lang) {
        var _this = this;
        console.log('Verify email');
        return this.$auth
            .applyActionCode(actionCode)
            .then(function (resp) {
            console.log('Email has been verified');
            _this.messages.push({
                icon: 'success',
                message: 'Succesfully registered'
            });
        })
            .catch(function (error) {
            console.error(error.message);
            _this.messages.push({
                icon: 'error',
                message: 'Oops! Something went wrong...'
            });
        })
            .then(function () {
            _this.loadingDots = false;
        });
    };
    VerifyEmail.prototype.handleResetPassword = function (actionCode, continueUrl, lang) {
        var _this = this;
        var accountEmail;
        return this.$auth
            .verifyPasswordResetCode(actionCode)
            .then(function (email) {
            var accountEmail = email;
            var newPassword = '';
            _this.$auth
                .confirmPasswordReset(actionCode, newPassword)
                .then(function (resp) {
            })
                .catch(function (error) {
                console.error(error.message);
            });
        })
            .catch(function (error) {
            console.error(error.message);
        });
    };
    VerifyEmail = __decorate([
        Component({
            components: {
                EmailValidationContent: EmailValidationContent
            }
        })
    ], VerifyEmail);
    return VerifyEmail;
}(Vue));
export default VerifyEmail;
