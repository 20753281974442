var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msg_container" },
    [
      _c("p", { staticClass: "is-size-5 has-text-gray" }, [
        _vm._v("- " + _vm._s(_vm.message))
      ]),
      _vm.icon == "dots"
        ? _c("beat-loader", {
            staticClass: "icons",
            attrs: { loading: _vm.loadingDots, color: "#474749", size: "8px" }
          })
        : _vm._e(),
      _vm.icon == "success"
        ? _c("b-icon", {
            staticClass: "icons",
            attrs: {
              icon: "check-circle",
              type: "is-success",
              size: "is-small"
            }
          })
        : _vm._e(),
      _vm.icon == "error"
        ? _c("b-icon", {
            staticClass: "icons",
            attrs: {
              icon: "exclamation-triangle",
              type: "is-danger",
              size: "is-small"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }